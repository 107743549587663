<template>
  <div class="header" v-if="$store.getters.getSiteInfo">
    <div class="logo" @click="toIndex">
      <img :src="$store.getters.getSiteInfo.logal">
      <span>{{$store.getters.getSiteInfo.name}}</span>
    </div>
    <div class="menu">
      <div class="menuList" v-for="(item, index) in menuList" :key="index" @click="menuItemClick(index)" :class="{ 'active': indexPath == '/account/mycenter' && index == 1 }">{{item}}</div>
    </div>
    <div class="right">
      <div class="lianxi" @mouseover="mouseOverKefu" @mouseleave="mouseLeaveKefu">
        <div class="box">
          <i class="el-icon-service icon"></i>
          <span>联系客服</span>
        </div>
        <div v-show="showKefu" class="show">
          <div @click="clickKefu">在线客服</div>
          <div @mouseover="mouseOverQR" @mouseleave="mouseLeaveQR">微信</div>
          <div v-show="showQR" class="qr">
            <img :src="$store.getters.getSiteInfo.wechat_service">
          </div>
        </div>
      </div>
      <div class="user" @mouseover="mouseOverUser" @mouseleave="mouseLeaveUser">
        <div class="box">
          <i class="el-icon-user icon"></i>
          <span>{{this.$store.getters.getUserInfo.nickname}}</span>
        </div>
        <div v-show="showUser" class="show">
          <div @click="toUser">个人中心</div>
          <div @click="logout">退出登陆</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['reload'],
  data() {
    return {
      menuList: ['首页', '礼品商城', '个人中心', '立即下单', '会员价格'],
      notices: [
        '拼多多一键导入下单 2020-12-0378789999',
        '淘宝一键导入下单 2020-12-03 09:15:11',
        '京东一键导入下单 2020-12-03 09:15:11'
      ],
      showKefu: false,
      showUser: false,
      showQR: false,
      count: 0
    }
  },
  props: {
    indexPath: String
  },
  // mounted() {
  //   this.handleShow()
  // },
  methods: {
    // handleShow(e) { // = 'API接口'
    //   if (localStorage.getItem('code')) {
    //     const arr = this.navList
    //     arr.some((item, index) => {
    //       console.log(item.path)
    //       // eslint-disable-next-line eqeqeq
    //       if (item.title == '首页' || item.title == '礼品商城' || item.title == '分站开通' || item.title == '系统公告' || item.title == '会员中心') {
    //         this.navList[5].title = ''
    //       }
    //     })
    //   }
    // },
    toIndex() {
      this.$router.push('/')
    },
    menuItemClick(index) {
      switch (index) {
        case 0:
          this.$router.push('/')
          break
        case 1:
          this.$router.push('/goods')
          break
        // case 2:
        //   this.$router.push('/notice')
        //   break
        // case 4:
        //   this.goPage('https://v.qq.com/x/page/e3216wwprvt.html')
        //   break
        case 2:
          this.$router.push('/account/mycenter')
          break
        case 3:
          this.$router.push('/orderAdmin/purchase')
          break
        case 4:
          this.$router.push('/account/myprice')
      }
    },
    // 跳转到第三方链接
    goPage(url) {
      window.location.href = url
    },
    mouseOverKefu() {
      this.showKefu = true
    },
    mouseLeaveKefu() {
      this.showKefu = false
    },
    mouseOverUser() {
      this.showUser = true
    },
    mouseLeaveUser() {
      this.showUser = false
    },
    mouseOverQR() {
      this.showQR = true
    },
    mouseLeaveQR() {
      this.showQR = false
    },
    toUser() {
      this.$router.push('/account/base')
    },
    // 点击在线客服
    clickKefu() {
      window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    },
    refresh() {
      this.reload()
    },
    // 退出登录
    logout() {
      this.$confirm('真的要注销登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.refresh()
        // 清楚用户信息,退出登录
        // this.$store.dispatch('logout')
        // localStorage.removeItem('userInfo')
        // localStorage.removeItem('token')
        localStorage.clear()// 清除缓存
        setTimeout(() => { location.reload() }, 200)
        this.$router.push('/index')
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped src="styles/components/adminHeader.css">
</style>
