import { LOGIN, QUERYHEADER, GETSITEINFO } from './mutation-types'

export default {
  // 登录
  [LOGIN] (state, data) {
    state.userInfo = data
    window.localStorage.setItem('userInfo', JSON.stringify(data))
  },
  // 退出登录
  // [LOGOUT] (state) {
  //   state.userInfo = null
  //   window.localStorage.removeItem('userInfo')
  //   // localStorage.clear()// 清除缓存
  // },
  // 查询表单数据
  [QUERYHEADER] (state, data) {
    state.queryHeaderFormData = data
  },
  // 用户信息
  [GETSITEINFO] (state, data) {
    state.siteInfo = data
    window.localStorage.setItem('siteInfo', JSON.stringify(data))
  }
}
