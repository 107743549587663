import { LOGIN, QUERYHEADER, GETSITEINFO } from './mutation-types'

export default {
  login ({ commit }, data) {
    commit(LOGIN, data)
  },
  // logout ({ commit }) {
  //   commit(LOGOUT)
  // },
  queryHeader ({ commit }, data) {
    commit(QUERYHEADER, data)
  },
  getSiteInfo ({ commit }, data) {
    commit(GETSITEINFO, data)
  }
}
