<template>
  <el-aside :width="isCollapse ? '65px' : '200px'">
    <div class="side-menu" :width="isCollapse ? '65px' : '200px'">
      <div class="tog-but" @click="togCollapse">
        <i :class="['icon', !isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold']" ></i>
      </div>
      <div class="buynow-btn" @click="orderNow" v-show="!isCollapse"> 立即下单 </div>
      <!-- <div class="buynow-btn" @click="personCenter" v-show="!isCollapse"> 个人中心 </div> -->
      <div class="personal" @click="personCenter"><i class="el-icon-user"></i>个人中心</div>
       <!-- width折叠和展开菜单时的宽度 -->
      <!-- style中的top，是，在折叠时，隐藏了下单按钮，并上移了菜单的top值 -->
      <el-menu :default-active="$route.path"
        :collapse="isCollapse"
        class="el-menu-vertical-demo"
        router @select="handleSelect"
        :width="isCollapse ? '65px' : '200px'"
        :style="{ top: isCollapse ? '100px' : '200px' }"
        :collapse-transition="false"
      >
        <el-submenu :index="item.index" v-for="(item, index) in menu" :key="index"
          :style="{ width: isCollapse ? '65px' : '200px' }" >
          <template slot="title">
            <i :class="item.icon"></i>
            <span>{{ item.title }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="(i, k) in item.items"
              :key="k"
              :index="i.path"
              >{{ i.name }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </div>
  </el-aside>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        // {
        //   index: '/account/mycenter',
        //   title: '个人中心',
        //   icon: 'el-icon-user'
        // },
        {
          index: '/orderAdmin',
          title: '订单管理',
          icon: 'el-icon-set-up',
          items: [
            {
              name: '礼品采购',
              path: '/orderAdmin/purchase'
            },
            {
              name: '订单列表',
              path: '/orderAdmin/orderList'
            },
            {
              name: '包裹列表',
              path: '/orderAdmin/parcelList'
            },
            {
              name: '店铺绑定',
              path: '/orderAdmin/storeBind'
            },
            {
              name: '发件地址',
              path: '/orderAdmin/sendAddress'
            }
          ]
        },
        {
          index: '/account',
          title: '个人中心',
          icon: 'el-icon-user',
          items: [
            {
              name: '基础信息',
              path: '/account/base'
            },
            {
              name: '余额管理',
              path: '/account/balance'
            },
            {
              name: '邀请奖励',
              path: '/account/inviteRewards'
            },
            {
              name: '邀请好友',
              path: '/account/InviteFriends'
            }
          ]
        },
        {
          index: '/workOrder',
          title: '工单中心',
          icon: 'el-icon-document',
          items: [
            {
              name: '提交工单',
              path: '/workOrder/orderSumbit'
            },
            {
              name: '我的工单',
              path: '/workOrder/myOrder'
            }
          ]
        }
        // {
        //   index: '/oem',
        //   title: '网站后台',
        //   icon: 'el-icon-monitor',
        //   items: [
        //     {
        //       name: '网站管理',
        //       path: '/oem/manage'
        //     }
        //   ]
        // }
      ],
      isCollapse: false // 菜单展开切换
    }
  },
  methods: {
    // 立即下单
    orderNow() {
      this.$router.push('/orderAdmin/purchase')
    },
    // 菜单激活回调
    handleSelect(indexPath) {
      this.$emit('getIndexPath', indexPath)
    },
    // 点击按钮切换展开
    togCollapse() {
      this.isCollapse = !this.isCollapse
    },
    personCenter() {
      this.isactive = 0
      this.$router.push('/account/mycenter')
    }
  }
}
</script>

<style scoped src="styles/components/sideMenu.css">
</style>

<style>
.side-menu .el-menu-vertical-demo.el-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.side-menu .el-menu-vertical-demo.el-menu::-webkit-scrollbar {
  display: none;
}

.side-menu .el-menu {
  border-right: 0px;
}

.side-menu .el-menu .el-menu-item {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}

.side-menu .el-submenu__title i:last-child {
  font-weight: 600;
}

.side-menu .el-submenu__title,
.side-menu .el-submenu__title i {
  color: #000000a6;
}

.side-menu .el-submenu.is-opened .el-submenu__title,
.side-menu .el-submenu.is-opened .el-submenu__title i {
  color: #1890ff;
}

.side-menu .el-menu .el-menu-item.is-active {
  color: #1890ff;
  border-right: 3px #1890ff solid;
}
.tog-but {
  height: 30px;
  /* background-color: aqua; */
  right: right;
  /* background-color: rgb(238, 238, 238); */
  font-size: 16px;
  /* line-height: 15px; */
  color: rgb(22, 22, 24);
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}
.tog-but:hover {
  color: rgb(24, 144, 255);
  background-color: rgb(236, 245, 255);
  border-radius: 2px;
  transition: all 0.2s;
}
</style>
