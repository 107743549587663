import Vue from 'vue'
import VueRouter from 'vue-router'

import BaseStructure from 'components/baseStructure/BaseStructure'

// eslint-disable-next-line no-unused-vars
function _isMobile() {
  const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/index'
  },
  // 首页
  {
    path: '/index',
    component: resolve => require(['views/index/Index'], resolve),
    meta: {
      title: '首页'
    }
    // beforeEnter: (to, from, next) => {
    //   if (_isMobile()) {
    //     // window.open('http://m.x.cn', '_self')
    //   } else {
    //     next()
    //   }
    // }
  },
  // 分站开通
  {
    path: '/join',
    component: resolve => require(['views/join/Join'], resolve),
    meta: {
      title: '分站开通'
    }
  },
  // 登录
  {
    path: '/login',
    component: resolve => require(['views/login/Login'], resolve),
    meta: {
      title: '登录'
    }
  },
  // 注册
  {
    path: '/register',
    component: resolve => require(['views/register/Register'], resolve),
    meta: {
      title: '注册'
    }
  },
  // 重置密码
  {
    path: '/rePwd',
    component: resolve => require(['views/rePwd/RePwd'], resolve),
    meta: {
      title: '重置密码'
    }
  },

  // 订单管理
  {
    path: '/orderAdmin',
    name: 'Purchase',
    redirect: '/orderAdmin/purchase',
    // redirect: '/account/base',
    component: BaseStructure,
    beforeEnter(to, from, next) {
      if (window.localStorage.getItem('userInfo')) {
        next()
      } else {
        next('login')
      }
    },
    children: [
      {
        path: 'purchase',
        component: resolve => require(['views/orderAdmin/purchase/Purchase'], resolve),
        meta: {
          title: '会员中心'
        }
      },
      {
        path: 'myprice',
        component: resolve => require(['views/account/myprice/myPrice'], resolve),
        meta: {
          title: '会员价格'
        }
      },
      {
        path: 'demo',
        component: resolve => require(['views/orderAdmin/demo'], resolve),
        meta: {
          title: 'demo'
        }
      },
      {
        path: 'orderList',
        component: resolve => require(['views/orderAdmin/orderList/OrderList'], resolve),
        meta: {
          title: '订单列表'
        }
      },
      {
        path: 'parcelList',
        component: resolve => require(['views/orderAdmin/parcelList/ParcelList'], resolve),
        meta: {
          title: '包裹列表'
        }
      },
      {
        path: 'storeBind',
        component: resolve => require(['views/orderAdmin/storeBind/StoreBind'], resolve),
        meta: {
          title: '店铺绑定'
        }
      },
      {
        path: 'sendAddress',
        component: resolve => require(['views/orderAdmin/sendAddress/SendAddress'], resolve),
        meta: {
          title: '发件地址'
        }
      }
    ]
  },
  // 系统公告
  {
    path: '/notice',
    name: 'notice',
    component: resolve => require(['views/notice/Notice'], resolve),
    meta: { title: '系统公告' }
  },
  // 会员价格
  // {
  //   path: '/vipprice',
  //   name: 'vipprice',
  //   component: resolve => require(['views/vipprice/vipPrice'], resolve),
  //   meta: { title: '会员价格' }
  // },

  // 公告
  // 个人中心
  {
    path: '/account',
    redirect: '/account/base',
    component: BaseStructure,
    children: [
      {
        path: 'mycenter',
        component: resolve => require(['views/account/mycenter/MyCenter'], resolve),
        meta: {
          title: '个人中心'
        }
      },
      {
        path: 'myprice',
        component: resolve => require(['views/account/myprice/myPrice'], resolve),
        meta: {
          title: '会员价格'
        }
      },
      {
        path: 'base',
        component: resolve => require(['views/account/base/Base'], resolve),
        meta: {
          title: '基础信息'
        }
      },
      {
        path: 'balance',
        component: resolve => require(['views/account/balance/Balance'], resolve),
        meta: {
          title: '余额管理'
        }
      },
      {
        path: 'inviteRewards',
        component: resolve => require(['views/account/inviteRewards/InviteRewards'], resolve),
        meta: {
          title: '邀请奖励'
        }
      },
      {
        path: 'inviteFriends',
        component: resolve => require(['views/account/inviteFriends/InviteFriends'], resolve),
        meta: {
          title: '邀请好友',
          keepAlive: true
        }
      }
    ]
  },

  // 工单中心
  {
    path: '/workOrder',
    redirect: '/workOrder/orderSumbit',
    component: BaseStructure,
    children: [
      {
        path: 'orderSumbit',
        component: resolve => require(['views/workOrder/orderSumbit/OrderSumbit'], resolve),
        meta: {
          title: '提交工单'
        }
      },
      {
        path: 'myOrder',
        component: resolve => require(['views/workOrder/myOrder/MyOrder'], resolve),
        meta: {
          title: '我的工单'
        }
      }
    ]
  },

  // 网站后台
  {
    path: '/oem',
    redirect: '/oem/manage',
    component: BaseStructure,
    children: [
      {
        path: 'manage',
        component: resolve => require(['views/oem/manage/Manage'], resolve),
        meta: {
          title: '网站管理'
        }
      }
    ]
  },
  // 礼品代发
  {
    path: '/goods',
    component: resolve => require(['views/goods/index'], resolve),
    meta: {
      title: '礼品商城'
    }
  }
  // {
  //   path: '/goods',
  //   component: resolve => require(['views/newView/index.vue'], resolve),
  //   // redirect: '/newView',
  //   meta: {
  //     title: '新页面'
  //   }
  // }
]

// eslint-disable-next-line no-unused-vars

const router = new VueRouter({
  mode: 'history',
  // mode: 'hash', // history去掉url中的#
  routes
})

export default router
