<template>
  <div>
    <div>
      <slot name="main-top"></slot>
    </div>
    <div class="main-content">
      <slot name="main-content"></slot>
    </div>
    <div class="main-bottom">
      Copyright © 2021 {{$store.getters.getSiteInfo.name ||''}} 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index">{{$store.getters.getSiteInfo.beian}}</a>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped src="styles/components/appMain.css">
</style>
